import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    menuSearch,
    selectModals,
    loadMenuItem,
    toggleMenuSearch,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import ModalListButton from '@/features/AdvancedPointOfSale/components/buttons/ModalListButton'
import SearchInput from '@/features/AdvancedPointOfSale/components/Search'

export default function SearchModal({ recentQueriesLimit=8 }) {

    const dispatch          = useDispatch()
    const { search:modal }  = useSelector(selectModals)
    const searchInputRef    = useRef()
    const [query, setQuery] = useState('')

    const recentQueries    = [...[...modal.recentQueries].reverse()].slice(0, (recentQueriesLimit - 1))
    const hasResults       = modal.results?.length > 0
    const hasRecentQueries = recentQueries?.length > 0

    const handleClose = () => {
        dispatch(toggleMenuSearch('close'))
    }

    const handleSearch = (arg=null) => {
        const text = (!!arg && typeof arg === 'string' ? arg : query).trim()

        if (!!!text || text === '') { return }

        dispatch(menuSearch(text)).finally(() => {
            setQuery('')
            searchInputRef.current.focus()
        })
    }

    const handleKeyDown = (e) => {
        e.stopPropagation()
        if (e.key === 'Escape') { handleClose() }
        if (e.key === 'Enter')  { handleSearch(e.target.value) }
    }

    const handleNavigateTo = (item) => {
        dispatch(loadMenuItem(item))
        handleClose()
    }

    const stickyContent = (
        <div className={hasRecentQueries ? 'pt-4 px-4 pb-3' : 'p-4'}>
            <div className='row'>
                <div className={hasRecentQueries ? 'col-9' : 'col-12'}>
                    <div className='row'>
                        <SearchInput
                            ref={searchInputRef}
                            searchInput={query}
                            placeholder='Search by item name...'
                            onBlur={(e) => setQuery(e.target.value)}
                            onFocus={(e) => setQuery(e.target.value)}
                            onSearch={(e) => setQuery(e.target.value)}
                        />
                    </div>
                </div>

                {
                    hasRecentQueries && (
                        <div className='col-3'>
                            <h4 className='border-bottom border-bottom-thick mt-2 pb-1'>
                                Recent Searches
                            </h4>
                        </div>
                    )
                }
            </div>
        </div>
    )

    const footerButtons = <>
        <button
            children="Close"
            className="btn btn-outline-secondary text-white text-bold mr-auto"
            onClick={handleClose}
        />

        <button
            children='Search'
            type='button'
            className='btn btn-primary ml-auto'
            disabled={!!!query}
            onClick={handleSearch}
        />
    </>

    useEffect(() => {
        if (modal.isOpen) {
            document.addEventListener('keydown', handleKeyDown, false)
            searchInputRef.current.focus()
        } else {
            document.removeEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [modal.isOpen])

    return modal.isOpen && (
        <Modal
            className='search-modal'
            title={modal.title}
            size={hasRecentQueries ? 'modal-full mt-5' : 'modal-md'}
            isOpen={modal.isOpen}
            prepend={stickyContent}
            footerButtons={footerButtons}
            onClose={handleClose}
        >
            {
                hasRecentQueries && (
                    <div className='px-4 pb-4'>
                        <div className='row'>
                            <div className={hasRecentQueries ? 'col-9' : 'col-12'}>
                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            hasResults && modal.results.map((result) => (
                                                <ModalListButton
                                                    key={result.item.menu_uuid}
                                                    containerClassName='px-0'
                                                    onClick={() => handleNavigateTo(result)}
                                                >
                                                    <span className='d-block pb-1'>{result.item.name}</span>

                                                    <span className='d-block text-gray5 text-capitalize'>
                                                        {result.breadcrumbs.map((node) => node.name).join(' \\ ')}
                                                    </span>
                                                </ModalListButton>
                                            ))
                                        }

                                        {
                                            Array.isArray(modal.results) && !hasResults && (
                                                <CenteredText
                                                    children='Nothing Found'
                                                    textClassName='text-gray2 mt-5 mb-4'
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='col-3'>
                                {
                                    recentQueries.map((query, index) => (
                                        <ModalListButton
                                            key={`${query}-${index}`}
                                            children={query}
                                            containerClassName='px-0'
                                            onClick={() => handleSearch(query)}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </Modal>
    )
}
