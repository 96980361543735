import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'

import {
    closeModal,
    configureModal,
    createCheck,
    fetchChecklessReservations,
    fetchResourceTypes,
    resetChecklessReservations,
    selectCheckslessReservations,
    selectModals,
    selectResourceTypes,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import BasicCard from '@/features/AdvancedPointOfSale/components/cards/BasicCard'
import CheckCard from '@/features/AdvancedPointOfSale/components/cards/CheckCard'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import NewNamedCheck from '../checks/NewNamedCheck'

const emptyMessage = (
    <CenteredText
        text='None Found'
        textClassName='text-gray4 h5 mt-3 py-3'
    />
)

const noResourceType = {
    id: -1,
    title: 'Retail Check',
    is_bookable: false,
}

export default function CreateCheckModal({ transactionsAllowed=false }) {

    const dispatch              = useDispatch()
    const { createCheck:modal } = useSelector(selectModals)
    const reservations          = useSelector(selectCheckslessReservations)
    const resourceTypes         = useSelector(selectResourceTypes)
    const modalRef              = useRef()
    const formMethods           = useForm({ mode: 'all' })
    const checkName             = formMethods.watch('check_name')

    const [previous, setPrevious]                      = useState(null)
    const [content, setContent]                        = useState(null)
    const [footerButtons, setFooterButtons]            = useState(null)
    const [verticallyCentered, shouldVerticallyCenter] = useState(true)

    const handleClose = () => {
        setContent(null)
        dispatch(closeModal('createCheck'))
    }

    const handleResourceTypeClick = (resourceType, e) => {
        highlightItem(e.target.closest('button'))

        switch(true) {
            // a bookable resource like an axe throwing reservation
            case resourceType?.is_bookable : {
                setFooterButtons(<>
                    { cancelButton }

                    <button
                        type='submit'
                        className='btn btn-primary ml-auto'
                        children='Next'
                        onClick={() => displayReservationsFor(resourceType)}
                    />
                </>)
                break
            }

            // an un-bookable resource type like a
            // restaurant table that we want to give a name
            case !resourceType?.is_bookable && resourceType?.id !== -1 : {
                setFooterButtons(<>
                    { cancelButton }

                    <button
                        type='submit'
                        className='btn btn-primary ml-auto'
                        children='Next'
                        onClick={() => displayNameFormFor(resourceType)}
                    />
                </>)
                break
            }

            // selecting "NONE" when choosing a resource type
            // to create an unassigned/anonmymous/retail-check
            case !resourceType?.is_bookable && resourceType?.id === -1 : {
                setFooterButtons(<>
                    { cancelButton }

                    <button
                        type='submit'
                        className='btn btn-primary ml-auto'
                        children='Create Retail Check'
                        onClick={() => dispatch(createCheck({
                            /* no resource type */
                            returnToMenuItemOnSuccess: !!modal.returnTo,
                            menuPath: modal?.returnTo?.menuPath,
                            itemId: modal?.returnTo?.itemId,
                            itemConfig: modal?.returnTo?.itemConfig,
                        }))}
                    />
                </>)
                break
            }

            default :
                throw('Unknown resource type click action!')
        }
    }

    const handleReservationClick = (check, e) => {
        highlightItem(e.target.closest('button'))

        setFooterButtons(<>
            {
                previous === 'choose_resource_type'
                    ? viewResourceTypesButton
                    : cancelButton
            }

            <button
                type='submit'
                className='btn btn-primary ml-auto'
                children='Create Check'
                onClick={() => {
                    dispatch(createCheck({
                        booking: check.booking,
                        returnToMenuItemOnSuccess: !!modal.returnTo,
                        menuPath: modal?.returnTo?.menuPath,
                        itemId: modal?.returnTo?.itemId,
                        itemConfig: modal?.returnTo?.itemConfig,
                    }))
                }}
            />
        </>)
    }

    const highlightItem = (el) => {
        // un-highlight sibling items
        modalRef.current.querySelectorAll('button').forEach((el) => el.classList.remove('bg-green', 'text-white'))

        // highlight the clicked item in a list
        el.classList.add('bg-green', 'text-white')
    }

    const displayReservationsFor = (resourceType=null) => {
        if (modal.action === 'choose_reservation') { return }

        dispatch(configureModal({ 
            modal: 'createCheck',
            config: {
                ...modal,
                action: 'choose_reservation',
                resourceType: resourceType,
                title: 'Select a Reservation',
            }
        }))

        if (!!resourceType) {
            dispatch(fetchChecklessReservations(resourceType.id))
        }
    }

    const displayResourceTypes = () => {
        if (modal.action === 'choose_resource_type') { return }

        dispatch(configureModal({
            modal: 'createCheck',
            config: {
                ...modal,
                action: 'choose_resource_type',
                resourceType: null,
                title: 'Select a Resource Type',
            }
        }))

        setFooterButtons(cancelButton)

        if (resourceTypes === null || resourceTypes?.length === 0) {
            dispatch(fetchResourceTypes())
        }
    }

    const displayNameFormFor = (resourceType=null) => {
        if (modal.action !== 'create_named_check') {
            dispatch(configureModal({
                modal: 'createCheck',
                config: {
                    ...modal,
                    action: 'create_named_check',
                    resourceType: resourceType,
                    title: 'Check Name',
                }
            }))
        }

        setFooterButtons(<>
            {
                previous === 'choose_resource_type'
                    ? viewResourceTypesButton
                    : cancelButton
            }

            <button
                id='create-named-check-submit-button'
                type='submit'
                className='btn btn-primary ml-auto'
                children='Create Check'
                disabled={!formMethods.formState.isValid}
                onClick={() => {
                    dispatch(createCheck({
                        check: { name: checkName },
                        resourceType: resourceType,
                        returnToMenuItemOnSuccess: !!modal.returnTo,
                        menuPath: modal?.returnTo?.menuPath,
                        itemId: modal?.returnTo?.itemId,
                        itemConfig: modal?.returnTo?.itemConfig,
                    }))
                }}
            />
        </>)
    }

    const cancelButton = (
        <button
            type='button'
            className='btn btn-link text-bold'
            children='Close'
            onClick={handleClose}
        />
    )

    const viewResourceTypesButton = (
        <button
            type='button'
            className='btn btn-link text-bold'
            children='Previous'
            onClick={() => displayResourceTypes()}
        />
    )

    // mount / unmount
    useEffect(() => {
        if (footerButtons === null) {
            setFooterButtons(cancelButton)
        }

        return () => {
            setPrevious(null)
            dispatch(resetChecklessReservations())
        }
    }, [])

    /*
     * Determine what is being displayed in the modal, potentially
     * set the modal action, and possibly fetch data if necessary
     */
    useEffect(() => {
        if (!modal.isOpen) {
            setContent(null)
            return
        }

        setPrevious(null)

        switch(true) {
            case modal.resourceType?.is_bookable : {
                displayReservationsFor(modal.resourceType)
                break
            }

            // display check name form
            case !modal.resourceType?.bookable && !!modal.resourceType?.id && modal.resourceType.id !== -2 : {
                displayNameFormFor(modal.resourceType)

                if (modal.mode === 'All') {
                    setPrevious('choose_resource_type')
                }
                break
            }

            default :
                setPrevious('choose_resource_type')
                displayResourceTypes()
        }
    }, [modal.isOpen, formMethods.formState.isValid, checkName])

    /*
     * Set content of the modal based of the modal's action
     */
    useEffect(() => {
        if (!modal?.action) { return }

        switch(modal.action)
        {
            // Display reservations without checks
            case 'choose_reservation' : {
                setContent(
                    reservations.length === 0
                        ? emptyMessage
                        : reservations.map((reservation, index) => (
                            <CheckCard
                                key={reservation.id}
                                data={{booking: reservation}}
                                className={`${reservations.length - 1 === index ? '' : 'mb-1'} w-100`.trim()}
                                showStaff={false}
                                onClick={handleReservationClick}
                            />
                        )
                ))
                setFooterButtons(
                    previous === 'choose_resource_type'
                        ? viewResourceTypesButton
                        : cancelButton
                )
                break
            }

             // Display resource types
            case 'create_unknown_resource_type_check' :
            case 'choose_resource_type' : {
                let tmpResourceTypes = [...resourceTypes]

                if (!transactionsAllowed) {
                    tmpResourceTypes = tmpResourceTypes.filter((resourceType) => !resourceType.is_bookable)
                }

                // Add a resource type of "NONE" to the top of the list if this is the
                // "ALL resource types" tab or the unknown resource type check modal
                //   - resource type of -1 is "ALL"
                //   - resource type of NULL is "unknown resource type check"
                if (modal.resourceType === null || (!!modal.resourceType?.id && modal.resourceType.id === -1)) {
                    tmpResourceTypes.unshift(noResourceType)
                }

                setContent(
                    tmpResourceTypes.length === 0
                        ? emptyMessage
                        : tmpResourceTypes.map((resourceType, index) => (
                            <BasicCard
                                key={resourceType.id}
                                data={resourceType}
                                className={`${index === 0 ? 'mt-0' : 'mt-1'} w-100`.trim()}
                                onClick={handleResourceTypeClick}
                            />
                    )
                ))
                break
            }

            case 'create_named_check' :
                setContent(<NewNamedCheck shouldVerticallyCenter={shouldVerticallyCenter} />)
                break

            default :
                throw('Unknown modal action!')
        }
    }, [modal.action, reservations, resourceTypes])

    return !!content && (
        <FormProvider {...formMethods}>
            <Modal
                ref={modalRef}
                className='create-check-modal'
                title={modal.title}
                size={modal.size}
                isOpen={!!content}
                verticallyCenter={verticallyCentered}
                footerButtons={footerButtons}
                onClose={handleClose}
            >
                <div id='create-check-form' autoComplete='off'
                    className='p-3 pb-0 w-100 d-flex flex-column justify-content-center'
                    children={content}
                />
            </Modal>
        </FormProvider>
    )
}
