import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    configureModal,
    closeModal,
    openModal,
    printReceipt,
    selectModals,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import { acknowledgementText } from '@/features/AdvancedPointOfSale/lib/Printers'
import { useConfirm } from '@/lib/useConfirmHook'

export default function PrintOptionModal() {

    const { confirm }            = useConfirm()
    const dispatch               = useDispatch()
    const { printOption: modal } = useSelector(selectModals)

    const handleClose = () => {
        dispatch(closeModal('printOption'))
    }

    const handlePrint = ({ numberOfCopies }) => {
        dispatch(printReceipt({
            ...modal.data,
            numberOfCopies: numberOfCopies || 1
        }))
        .then((data) => {
            if (data.success) {
                handleClose()

                if (data.with_errors) {
                    const delay = 1800

                    dispatch(configureModal({
                        modal: 'popModal',
                        config: { text: data.message, icon: 'fa-print bg-warning', delay }
                    }))
                    dispatch(openModal('popModal'))

                    window.setTimeout(async () => {
                        if (
                            !await confirm(
                                acknowledgementText(data, data.jobs_created), {
                                header_text: 'Warning!',
                                confirm_only: true,
                                confirm_text: 'OK',
                                dangerous: true,
                                rawHTML: true,
                                size: 'md'
                            })
                        )  {
                            return
                        }
                    }, delay + 50)
                }
            }
        })
    }

    return modal.isOpen && (
        <Modal
            title={modal.title}
            size={modal.size}
            isOpen={modal.isOpen}
            footerButtons={
                <button
                    children="Close"
                    className="btn btn-link mx-auto w-50"
                    onClick={handleClose}
                />
            }
            onClose={handleClose}
        >
            <div className="d-flex justify-content-between align-items-center py-4 mt-2">
                <button
                    children="One Copy"
                    className="btn btn-primary w-50 mx-4 py-4"
                    onClick={handlePrint}
                />
                <button
                    children="Two Copies"
                    className="btn btn-primary w-50 mx-4 py-4"
                    onClick={() => handlePrint({ numberOfCopies: 2 })}
                />
            </div>
        </Modal>
    )
}
