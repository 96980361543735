import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectCurrentCheck,
    selectModals,
    closeModal,
    moveCheckItemsBetweenTabs,
    configureModal,
    setInMoveItemsMode,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import ModalListButton from '@/features/AdvancedPointOfSale/components/buttons/ModalListButton'
import { isOpen, inAlteration } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function SelectTabModal() {

    const dispatch            = useDispatch()
    const { selectTab:modal } = useSelector(selectModals)
    const check               = useSelector(selectCurrentCheck)

    const tabOptions = (
        (check?.tabs || [])
            .filter((tab) => tab.id !== modal.fromTabId)
            .filter((tab) => !tab.is_booking)
            .filter((tab) => !tab.payment_locked_by_id)
            .filter((tab) => (isOpen(check) && !tab.is_closed) || (inAlteration(check) && tab.is_closed))
    )

    const handleClose = () => {
        dispatch(configureModal({ modal: 'selectTab', config: { uuids: [], fromTabId: null, toTabId: null } }))
        dispatch(closeModal('selectTab'))
        dispatch(setInMoveItemsMode(false))
    }

    const handleMove = () => {
        dispatch(moveCheckItemsBetweenTabs()).then(() => handleClose())
    }

    const footerButtons = <>
        <button
            type='button'
            className='btn btn-outline-secondary text-white text-bold mr-auto'
            children='Close'
            onClick={handleClose}
        />
        <button
            children="Move"
            className="btn btn-primary ml-auto"
            disabled={!modal.toTabId}
            onClick={handleMove}
        />
    </>

    return modal.isOpen && (
        <Modal
            isOpen={modal.isOpen}
            title='Move Items To...'
            size={modal.size}
            footerButtons={footerButtons}
            onClose={handleClose}
            className='select-tab-modal'
        >
            <div className='py-3'>
                {
                    tabOptions.map((tab) => (
                        <ModalListButton
                            key={tab.id}
                            children={
                                <div className='d-flex align-content-center'>
                                    { tab.is_tab_of_primary_check_owner && <i className='fas fa-star text-yellow mr-2' /> }
                                    <span>{tab.is_general && check.booking?.name || tab.name}</span>
                                    <TabStatus className={modal.toTabId === tab.id ? 'text-white' : 'text-green'} tab={tab} />
                                </div>
                            }
                            containerClassName='mx-4 my-1'
                            selected={modal.toTabId === tab.id}
                            onClick={() => dispatch(configureModal({ modal: 'selectTab', config: { toTabId: tab.id }}))}
                        />
                    ))
                }

                {
                    tabOptions.length === 0 && (
                        <CenteredText className='my-5' text='No Tabs Found' textClassName='text-gray2' />
                    )
                }
            </div>
        </Modal>
    )
}
