import React, { useEffect, useMemo } from 'react'
import Input from '../Form/Input'
import { validationPatterns } from '@/lib/Errors'
import Select from '../Form/Select'
import Checkbox from '../Form/Checkbox'
import FormRow from '../Form/FormRow'
import titleize from "../../lib/String"
import { debug } from '@/lib/Debug'

const formattedOptions = (roles) => {
    if (!roles) { return [] }

    return Object.keys(roles).map((role) => ({
        value: role,
        display: (
            /^(staff)$/i.test(role)
                ? 'Front Desk Staff'
                : /^(shift_manager)$/i.test(role)
                    ? 'Asst. Manager'
                    : titleize(role)
        )
    }))
}

const formattedCompanies = (companies) => {
    if (!companies) { return [] }
    return companies.map((company) => {
        return { value: company.id, display: company.name }
    })
}

const formattedLocations = (locations) => {
  if (!locations) { return [] }
  return locations.map((location) => {
    return { value: location.id, display: location.name }
  })
}

export default function UserForm({
    roles,
    companies,
    locations,
    pos_locations,
    is_current_user=false,
    current_company_id,
    location_level,
    form,
    errors,
    new: newUser,
    handleUpdate,
    handleManualUpdate,
    handleFormUpdate,
    handleCheckboxUpdate,
}) {

    let nameColumns, rolesColumns, managerCodeColumns, pinColumns

    const shouldHavePosPin = useMemo(() => (form.pos_location_ids || []).length > 0, [form.pos_location_ids])

    switch(true) {
      // name + roles + manager code
      case roles && Object.keys(roles).length > 0 && !/staff|server_coach|bartender/i.test(form.role) :
        nameColumns        = 5
        rolesColumns       = 3
        managerCodeColumns = shouldHavePosPin ? 2 : 4
        pinColumns         = shouldHavePosPin ? 2 : 0
        break

      // name + roles only
      case roles && Object.keys(roles).length > 0 || /staff|server_coach|bartender/i.test(form.role) :
        nameColumns        = 5
        rolesColumns       = shouldHavePosPin ? 5 : 7
        managerCodeColumns = 0
        pinColumns         = shouldHavePosPin ? 2 : 0
        break

      // name + manager code only
      case roles && Object.keys(roles).length <= 0 || !/staff|server_coach|bartender/i.test(form.role) :
        nameColumns        = 7
        rolesColumns       = 0
        managerCodeColumns = shouldHavePosPin ? 3 : 5
        pinColumns         = shouldHavePosPin ? 2 : 0
        break
    }

    const handleLocationsUpdate = (e) => {
        const values = Array.from(e.target.selectedOptions, option => parseInt(option.value), 10)
        handleManualUpdate(e.target.name === 'locations' ? 'location_ids' : 'pos_location_ids', values)
    }

    const handleRoleUpdate = (e) => {
        handleFormUpdate({ ...form, ...{ [e.target.name]: e.target.value, location_ids: [] }})
    }

    useEffect(() => {
      if (debug && console) {
        console.log(
            roles,
            Object.keys(roles).length,
            companies,
            formattedLocations(filteredLocations(locations))
        )
      }
    }, [])

    useEffect(() => {
        if (form.role && form.role !== "super") {
            if (form.company_id) {
                handleManualUpdate('company_id', form.company_id)
            } else {
                handleManualUpdate('company_id', current_company_id || "")
            }
        } else {
            handleManualUpdate('company_id', null)
        }
    }, [form.role, form.company_id])

    const filteredLocations = (locations) => {
        const companyId = form.company_id ? form.company_id : current_company_id
        return locations.filter(location => location.company_id == companyId)
    }

    return (
        <div>
            <FormRow>
                <Input
                    name='name'
                    handleChange={handleUpdate}
                    validation={{ 'name': { required: true }}}
                    value={form.name}
                    cols={nameColumns}
                    errors={errors} />

                { Object.keys(roles).length > 0 &&
                    <Select
                        validation={{ 'role': { required: true }}}
                        options={formattedOptions(roles)}
                        value={form.role}
                        cols={rolesColumns}
                        errors={errors}
                        includeBlank
                        handleChange={handleRoleUpdate}
                    />
                }

                { !/staff|server_coach|bartender/i.test(form.role) && (
                    <Input
                        label='Manager Code'
                        validation={{ 'manager_code': { required: !!location_level && !/staff|server_coach|bartender/i.test(form.role) }}}
                        req={!!location_level}
                        readOnly={!location_level}
                        handleChange={handleUpdate}
                        placeholder='XXXX'
                        value={form.manager_code}
                        cols={managerCodeColumns}
                        errors={errors} />
                )}

                {
                    shouldHavePosPin && (
                        <Input
                            label='Screen Lock PIN'
                            handleChange={handleUpdate}
                            placeholder='XXXX'
                            mask='9999'
                            req={!!location_level}
                            value={String(form.pin)}
                            validation={{ 'pin': { required: !!location_level }}}
                            readOnly={!location_level}
                            cols={pinColumns}
                            errors={errors}
                        />
                    )
                }
            </FormRow>

            <FormRow>
                <Input
                    validation={{ 'username': { required: true }}}
                    handleChange={handleUpdate}
                    value={form.username}
                    cols='5'
                    errors={errors} />

                <Input
                    mask='999-999-9999'
                    name='phone_number'
                    handleChange={handleUpdate}
                    value={form.phone_number}
                    req={false}
                    validation={{ 'phone_number': {
                        required: false,
                        pattern: validationPatterns.phone,
                    }}}
                    cols='7'
                    errors={errors} />
            </FormRow>

            <FormRow>
                <Input
                    handleChange={handleUpdate}
                    req={true}
                    value={form.email}
                    validation={{ 'email': {
                        required: true,
                        pattern: validationPatterns.email,
                    }}}
                    cols='5'
                    errors={errors} />

                {
                    !/super/i.test(form.role) && (
                        <div className="col-6 form-group">
                            <label>Emails To Receive</label>
                            <div className="row mt-2 pt-1">
                                <Checkbox
                                    cols='12'
                                    label='Staff Confirmation'
                                    name='staff_reservation_email_recipient'
                                    value={form.staff_reservation_email_recipient}
                                    className='text-dark'
                                    errors={errors}
                                    handleChange={handleCheckboxUpdate}
                                />

                                <Checkbox
                                    cols='12'
                                    label='Chargeback & Fraud Notifications'
                                    name='chargeback_or_fraud_notification_email_recipient'
                                    value={form.chargeback_or_fraud_notification_email_recipient}
                                    className='text-dark'
                                    errors={errors}
                                    handleChange={handleCheckboxUpdate}
                                />
                            </div>
                        </div>
                    )
                }
            </FormRow>

            { form.role && <>
                <FormRow>
                    {!(/super/i.test(form.role)) && (
                        <Select
                            validation={{ 'company_id': { required: !(/super/i.test(form.role)) }}}
                            label='Company'
                            options={formattedCompanies(companies)}
                            value={form.company_id}
                            cols='5'
                            errors={errors}
                            includeBlank
                            handleChange={handleUpdate}
                        />
                    )}

                </FormRow>

                <FormRow>
                    {!(/company_admin/i.test(form.role) || /super/i.test(form.role)) ? (
                        <Select
                            label='General Location Assignment'
                            validation={{ 'locations': { required: !(/company_admin/i.test(form.role) || /super/i.test(form.role)) }}}
                            options={formattedLocations(filteredLocations(locations))}
                            multiple={/^manager$/i.test(form.role)}
                            value={/^manager$/i.test(form.role) ? (form.location_ids || []) : (form.location_ids?.[0] || '')}
                            cols='5'
                            errors={errors}
                            handleChange={handleLocationsUpdate}
                        />
                    ) : filteredLocations(locations).length > 1 && (
                        <div className='col-5'>
                            <div className='form-group'>
                                <label>General Location Assignment</label>
                                <strong className='text-dark d-block pt-1'>ALL LOCATIONS</strong>
                            </div>
                        </div>
                    )}

                    {filteredLocations(pos_locations).length > 0 &&
                        <Select
                            label='Advanced POS Device Access'
                            validation={{ 'pos_locations': { required: false }}}
                            options={formattedLocations(filteredLocations(pos_locations))}
                            multiple
                            value={form.pos_location_ids || []}
                            cols='5'
                            req={false}
                            errors={errors}
                            handleChange={handleLocationsUpdate}
                        />
                    }
                </FormRow>
            </>}
        </div>
    )
}
