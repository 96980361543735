import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    closeModal,
    selectModals,
    addMembershipToTab,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import { addAlert } from '@/features/Notifications/notificationSlice'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'

export default function AddMembershipModal() {

    const dispatch                        = useDispatch()
    const { addMembership:modal }         = useSelector(selectModals)
    const [membershipId, setMembershipId] = useState(null)
    const [error, setError]               = useState(null)

    const handleClose = () => {
        setMembershipId(null)
        setError(null)
        dispatch(closeModal('addMembership'))
    }

    const handleAdd = () => {
        dispatch(addMembershipToTab(modal.tab.id, membershipId))
        .then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                handleClose()
            } else {
                setError(data.message)
            }
        })
    }

    return modal.isOpen && (
        <Modal
            className='add-membership-modal'
            title={`${modal.tab?.membership ? 'Edit' : 'Add'} Membership`}
            isOpen={modal.isOpen}
            footerButtons={<>
                <button
                    type='button'
                    className='btn btn-outline-secondary text-white text-bold mr-auto'
                    children='Close'
                    onClick={handleClose}
                />
                <button
                    type='button'
                    children={modal.tab?.membership ? 'Edit' : 'Add'}
                    className="btn btn-primary ml-auto"
                    disabled={!membershipId}
                    onClick={handleAdd}
                />
            </>}
            onClose={handleClose}
        >
            <div className="p-4">
                <input
                    name='new_tab_name'
                    className='form-control p-3'
                    placeholder='Enter Membership ID'
                    value={membershipId || ''}
                    spellCheck={false}
                    onChange={e => setMembershipId(e.target.value)}
                />
                {error &&
                    <div className="invalid-feedback d-block">
                        {error}
                    </div>
                }
            </div>
        </Modal>
    )
}
