import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    addItemToChitTabs,
    openSidebarTab,
    closeModal,
    fetchChitTabs,
    createChitForCheck,
    selectCurrentChit,
    selectModals,
    selectCurrentCheck,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import CenteredText from '@/features/AdvancedPointOfSale/components/CenteredText'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import Incrementer from '@/features/AdvancedPointOfSale/components/Incrementer'

const emptyMessage = (
    <CenteredText text="None Found" textClassName="text-gray4 h5 mt-3 py-3" />
)

export default function AddToChitTabModal() {

    const dispatch               = useDispatch()
    const { addToChitTab:modal } = useSelector(selectModals)
    const check                  = useSelector(selectCurrentCheck)
    const chit                   = useSelector(selectCurrentChit)

    const [tabs, setTabs]             = useState([])
    const [content, setContent]       = useState(null)
    const [itemCounts, setItemCounts] = useState({})
    const [isAdding, setIsAdding]     = useState(null)

    const resetModalState = () => {
        setItemCounts({})
        setTabs([])
        setContent(null)
    }

    const handleCalc = (value, tabId) => {
        if (value > 99) { return }

        setItemCounts({ ...itemCounts, [tabId]: value })
    }

    const handleClick = (tabId, action) => {
        const currentValue = parseInt(itemCounts[tabId]) || 0

        if (action === 'plus' && currentValue < 99) {
            setItemCounts({ ...itemCounts, [tabId]: currentValue + 1 })
        }

        if (action === 'minus' && currentValue > 0) {
            setItemCounts({ ...itemCounts, [tabId]: currentValue - 1 })
        }
    }

    const handleClose = () => {
        resetModalState()
        dispatch(closeModal('addToChitTab'))
    }

    const handleAddItem = () => {
        setIsAdding(true)

        dispatch(addItemToChitTabs(chit.id, itemCounts, modal.duplicateItem))
        .then((data) => {
            if (data.success) {
                dispatch(closeModal('addToChitTab'))
                dispatch(openSidebarTab({ sidebar: 'checkAndChit', tabIndex: 1 }))
            }
        })
        .finally(() => {
            setIsAdding(false)
            resetModalState()
        })
    }

    const footerButtons = useMemo(() => <>
        <button
            type='button'
            className='btn btn-outline-secondary text-white text-bold mr-auto'
            children='Close'
            onClick={handleClose}
        />
        <button
            children={isAdding ? 'Please Wait' : 'Add Items'}
            className="btn btn-primary ml-auto"
            disabled={
                isAdding
                || tabs.length === 0
                || !Object.values(itemCounts).find((count) => parseInt(count) > 0)
            }
            onClick={handleAddItem}
        />
    </>, [chit, tabs, itemCounts, isAdding])

    useEffect(() => {
        if (!modal.isOpen || !check) { return }

        if (!!chit) {
            dispatch(fetchChitTabs(chit.id)).then((data) => {
                setTabs(data.filter((tab) => !tab.is_booking))
            })
        } else {
            dispatch(createChitForCheck(check.id))
        }
    }, [modal.isOpen, check, chit])

    useEffect(() => {
        setContent(
            tabs.map((tab) => {
                const incrementerValue = parseInt(itemCounts[tab.id]) || 0

                return (
                    <div key={tab.id} className="menu-button">
                        <div className={`btn d-flex justify-content-between`}>
                            <span className='tab-name d-flex-inline pr-4 text-truncate'>
                                { tab.is_tab_of_primary_check_owner && <i className='fas fa-star text-yellow mr-2' /> }
                                { tab.is_general && check.booking?.name || tab.name }

                                <TabStatus tab={check.tabs.find(checkTab => checkTab.uuid === tab.uuid)} />
                            </span>

                            <Incrementer
                                min='0'
                                max='99'
                                value={incrementerValue}
                                flashCounter={incrementerValue}
                                disableDecrement={incrementerValue === 0}
                                disableIncrement={incrementerValue === 99}
                                onDecrease={() => handleClick(tab.id, 'minus')}
                                onIncrease={() => handleClick(tab.id, 'plus')}
                                onChange={(value) => handleCalc(value, tab.id)}
                            />
                        </div>
                    </div>
                )
            })
        )
    }, [tabs, itemCounts])

    return modal.isOpen && (
        <Modal
            className='add-to-chit-tab-modal'
            title={modal.title || 'Add Item'}
            isOpen={modal.isOpen}
            footerButtons={footerButtons}
            onClose={handleClose}
        >
            <div className="add-to-tab-modal-content p-4 w-100 d-flex flex-column justify-content-center">
                {tabs.length > 0 ? content : emptyMessage}
            </div>
        </Modal>
    )
}
