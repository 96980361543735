import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { moveChitItemBetweenTabs,
  selectCurrentChit,
  selectModals,
  selectCurrentCheck,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import { sortedAndFilteredTabs } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function MoveChitItemModal({ handleClose=() => {} }) {

  const dispatch    = useDispatch()
  const currentChit = useSelector(selectCurrentChit)
  const check       = useSelector(selectCurrentCheck)

  const { manageChitItem:modal } = useSelector(selectModals)
  const sortedTabs               = useMemo(() => sortedAndFilteredTabs(currentChit.tabs), [currentChit])

  const [selectedTab, setSelectedTab] = useState(null)

  const handleSave = () => {
      dispatch(moveChitItemBetweenTabs(modal.tab.id, selectedTab.id))
      handleClose()
  }

  // @note: Modal wrapper component comes from the
  // ManageChitItemModal which loads this content inside of itself
  return !!modal?.tab?.id && <>
      {
        sortedTabs
          .filter((tab) => tab.id !== modal.tab.id)
          .map((tab) => (
            <div key={tab.id} className='menu-button'>
              <button
                key={tab.id}
                className={tab.id === selectedTab?.id ? 'selected' : ''}
                onClick={() => setSelectedTab(tab)}
              >
                <div className='d-flex align-content-center'>
                  { tab.is_general && check.booking?.name || tab.name }

                  <TabStatus
                    className={tab.id === selectedTab?.id ? 'text-white' : 'text-green'}
                    tab={check.tabs.find(checkTab => checkTab.uuid === tab.uuid)}
                  />
                </div>

                {
                  tab.id === selectedTab?.id && (
                    <i className="fa-solid fa-check" />
                  )
                }
              </button>
            </div>
          ))
      }

      <hr className='mt-4 mb-2' />

      <button
        children='Save'
        className='btn btn-primary w-100 mt-3'
        disabled={!selectedTab}
        onClick={handleSave}
      />

      <button
        children='Close'
        className='btn btn-outline-secondary w-100 text-white mt-3'
        onClick={handleClose}
      />
  </>
}
