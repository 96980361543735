import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectCurrentCheck,
    selectModals,
    closeModal,
    configureModal,
    createOrUpdateAlteration,
    deleteAlteration,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Modal from '@/features/AdvancedPointOfSale/components/Modal'
import Checkbox from '@/components/Form/Checkbox'
import { useConfirm } from '@/lib/useConfirmHook'

export default function AlterationModal() {

    const dispatch             = useDispatch()
    const { confirm }          = useConfirm()
    const check                = useSelector(selectCurrentCheck)
    const { alteration:modal } = useSelector(selectModals)

    const isValid = (
        (!!modal.amount && !isNaN(Number.parseFloat(modal.amount)) && Number.parseFloat(modal.amount) > 0)
        && !!modal.towards_type
        && !!modal.amount_type
        && !!modal.tab_id
    )

    const handleClose = () => {
        dispatch(configureModal({ modal: 'alteration', config: {
            mode: 'create',
            amount: null,
            amount_type: 'debit',
            notes: null,
            tab_id: null,
            towards_type: null,
            uuid: null,
        }}))
        dispatch(closeModal('alteration'))
    }

    const handleChangeAmount = (e) => {
        const value = e.target.value
        dispatch(configureModal({ modal: 'alteration', config: { amount: value } }))
    }

    const handleChangeAmountType = () => {
        const value = modal.amount_type === 'credit' ? 'debit' : 'credit'
        dispatch(configureModal({ modal: 'alteration', config: { amount_type: value } }))
    }

    const handleChangeNote = (e) => {
        const value = e.target.value
        dispatch(configureModal({ modal: 'alteration', config: { notes: value } }))
    }

    const handleChangeTowardsType = () => {
        const value = modal.towards_type === 'booking' ? 'fab' : 'booking'
        dispatch(configureModal({ modal: 'alteration', config: { towards_type: value } }))
    }

    const handleDelete = async () => {
        if (await confirm('Are you sure?', { style: { zIndex: 999999 } })) {
            dispatch(deleteAlteration({
                check_id: check.id,
                tab_id: modal.tab_id,
                uuid: modal.uuid,
            }))
            .then(() => handleClose())
        }
    }

    const handleSubmit = async () => {
        dispatch(createOrUpdateAlteration({
            amount: modal.amount,
            amount_type: modal.amount_type,
            check_id: check.id,
            mode: modal.mode,
            notes: modal.notes,
            tab_id: modal.tab_id,
            towards_type: modal.towards_type,
            uuid: modal.uuid,
        }))
        .then(() => handleClose())
    }

    const footerButtons = <>
        <button
            type='button'
            className='btn btn-outline-secondary text-white text-bold mr-auto'
            children='Close'
            onClick={handleClose}
        />
        {
            modal.mode === 'edit' && <>
                <button
                    type='button'
                    children='Delete'
                    className='btn btn-outline-danger mr-2'
                    onClick={handleDelete}
                />
            </>
        }
        <button
            type='button'
            children={modal.mode === 'create' ? 'Submit' : 'Save'}
            className='btn btn-primary'
            disabled={!isValid}
            onClick={handleSubmit}
        />
    </>

    useEffect(() => {
        if (modal.towards_type === null) {
            const value = !!check?.booking ? 'booking' : 'fab'
            dispatch(configureModal({ modal: 'alteration', config: { towards_type: value } }))
        }
    }, [modal, check])

    return modal.isOpen && (
        <Modal
            isOpen={modal.isOpen}
            title={`${modal.mode === 'create' ? '' : 'Edit' } Manager Adjustment`}
            size={modal.size}
            className='alteration-modal'
            footerButtons={footerButtons}
            onClose={handleClose}
        >
            <div className='p-4 mb-2'>
                <div className='input-group mb-3'>

                    {
                        !!check?.booking && <>
                            <h5 className='mb-4 border-bottom pb-1 w-100'>
                                What should this adjustment apply towards?
                            </h5>

                            <div className='form-group pb-0 pl-3'>
                                <Checkbox
                                    name='booking'
                                    label='The Reservation'
                                    value={modal.towards_type === 'booking'}
                                    handleChange={handleChangeTowardsType}
                                />

                                <Checkbox
                                    name='fab'
                                    label='Food / Beverage / Merchandise / Other'
                                    value={modal.towards_type === 'fab'}
                                    handleChange={handleChangeTowardsType}
                                />
                            </div>

                            <hr className='mt-2 mb-4 pb-1 border-thick' />
                        </>
                    }

                    <div className='input-group-prepend'>
                        <span
                            children={<i className='fas fa-lg fa-dollar' />}
                            className='input-group-text bg-gray4 border-color-gray4 text-white px-3'
                            style={{ borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem' }}
                        />
                    </div>

                    <input
                        className='form-control border-color-gray4'
                        type='number'
                        min='0'
                        step='0.1'
                        value={modal.amount || ''}
                        placeholder='123.45'
                        onChange={handleChangeAmount}
                    />

                    <div className='input-group-append'>
                        <button
                            type='button'
                            children={modal.amount_type === 'credit' ? 'Add' : 'Deduct'}
                            className={[
                                'btn',
                                `btn-${modal.amount_type === 'credit' ? 'success' : ''}`,
                                `btn-${modal.amount_type === 'debit' ? 'danger' : ''}`,
                                'text-white',
                                'font-weight-bold',
                                'py-0',
                                'px-3',
                            ].join(' ')}
                            onClick={handleChangeAmountType}
                        />
                    </div>
                </div>

                <textarea
                    className='form-control w-100'
                    placeholder="Notes"
                    value={modal.notes || ''}
                    onChange={handleChangeNote}
                />
            </div>
        </Modal>
    )
}
