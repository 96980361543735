import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    closeModal,
    configureModal,
    selectModals,
    renameTab,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'
import Modal from '@/features/AdvancedPointOfSale/components/Modal'

export default function RenameTabModal() {

    const dispatch            = useDispatch()
    const { renameTab:modal } = useSelector(selectModals)

    const handleClose = () => {
        dispatch(closeModal('renameTab'))
    }

    const handleRename = () => {
        dispatch(renameTab())
        handleClose()
    }

    const handleNameUpdate = (e) => {
        dispatch(configureModal({ modal: 'renameTab', config: { newName: e.target.value }}))
    }

    return modal.isOpen && (
        <Modal
            className='rename-tab-modal'
            title='Rename Tab'
            isOpen={modal.isOpen}
            footerButtons={<>
                <button
                    type='button'
                    className='btn btn-outline-secondary text-white text-bold mr-auto'
                    children='Close'
                    onClick={handleClose}
                />
                <button
                    type='button'
                    children='Rename'
                    className="btn btn-primary ml-auto"
                    disabled={!modal.newName}
                    onClick={handleRename}
                />
            </>}
            onClose={handleClose}
        >
            <div className="p-4">
                <input
                    name='new_tab_name'
                    className='form-control p-3'
                    placeholder='Enter Tab Name'
                    value={modal.newName || ''}
                    onChange={handleNameUpdate}
                />
            </div>
        </Modal>
    )
}
