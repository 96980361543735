import React, { useEffect, useState } from 'react'
import FormRow from '../Form/FormRow'
import Input from '../Form/Input'
import Image from '../Form/Image'
import axios from 'axios'
import Checkbox from '../Form/Checkbox'
import Select from "../Form/Select"
import Helpjuice from '@/features/Helpjuice/Helpjuice'

const formattedAdmins = (superAdmins) => {
    if (!superAdmins) { return [] }
    return Object.values(superAdmins).map((superAdmin) => {
        return { value: superAdmin.id, display: superAdmin.name }
    })
}

export default function CompanyForm({ handleUpdate, handleManualUpdate, handleCheckboxUpdate, form, errors, directUploadUrl, imagesUrl, superAdmins, superUser=false }) {

    const [previewImages, setPreviewImages] = useState({
        currentLogo:                 null,
        currentDarkLogo:             null,
        currentAppLogo:              null,
        currentTargetImage:          null,
        currentModalBackgroundImage: null
    })

    const [imagesFetched, setImagesFetched] = useState(false)
    const [imagesLoading, setImagesLoading] = useState(false)

    useEffect(() => {
        // we don't do anything if we've already fetched the images or if we have no url to fetch from (new record)
        if (imagesFetched || !imagesUrl) {
            return
        }

        // turn on spinners
        setImagesLoading(true)

        axios.get(imagesUrl).then(({ data }) => {
            setPreviewImages({
                currentLogo:        data.logo,
                currentDarkLogo:    data.dark_logo,
                currentAppLogo:     data.app_logo,
                currentTargetImage: data.target_image,
            })
        }).catch((error) => {
            console.warn('Unable to load images...')
        }).finally(() => {
            setImagesLoading(false)
            setImagesFetched(true)
        })
    }, [imagesFetched])

    return (
        <div>
            <h3>
                Company Information
                <Helpjuice articleId='company-information' extraClasses='' />

                <Checkbox
                  name='is_active'
                  className="float-right"
                  label="Active?"
                  value={form.is_active}
                  handleChange={handleCheckboxUpdate}
                />
            </h3>

            <FormRow>
                <Input
                    cols='6'
                    value={form.name}
                    errors={errors}
                    validation={{ 'name': { required: true }}}
                    handleChange={handleUpdate}
                />

                <Input
                    cols='6'
                    value={form.website}
                    errors={errors}
                    validation={{ 'website': { required: true }}}
                    handleChange={handleUpdate}
                />
            </FormRow>

            <FormRow>
                <Input
                    cols='6'
                    value={form.customer_verbiage_singular}
                    errors={errors}
                    validation={{ 'customer_verbiage_singular': { required: true }}}
                    handleChange={handleUpdate}
                />

                <Input
                    cols='6'
                    value={form.customer_verbiage_plural}
                    errors={errors}
                    validation={{ 'customer_verbiage_plural': { required: true }}}
                    handleChange={handleUpdate}
                />
            </FormRow>

            <FormRow>
                <Input
                    name='subdomain'
                    label='Booking Subdomain'
                    value={form.subdomain}
                    cols='12'
                    errors={errors}
                    validation={{ subdomain: { required: true } }}
                    handleChange={handleUpdate}
                    disabled={!superUser}
                />
            </FormRow>

            <FormRow>
                <Checkbox
                  cols='12'
                  name='allow_minor_waivers'
                  className='pt-2 px-1'
                  label="Allow minor waivers?"
                  value={form.allow_minor_waivers}
                  handleChange={handleCheckboxUpdate}
                />
            </FormRow>

            { superUser && (
                <FormRow>
                    <Select
                        name='salesperson_id'
                        label='Salesperson'
                        handleChange={handleUpdate}
                        includeBlank={true}
                        options={formattedAdmins(superAdmins)}
                        value={form.salesperson_id}
                        cols='6'
                        errors={errors}
                    />
                </FormRow>
            )}

            <h3 className="mt-5">
                Default Branding
                <Helpjuice articleId='default-company-branding' extraClasses='' />
            </h3>

            <Image
                loading={imagesLoading}
                name='logo'
                label='Light Mode Logo'
                handleChange={handleManualUpdate}
                previewUrl={previewImages.currentLogo}
                directUploadUrl={directUploadUrl}
                tooltip='Minimum 512 x 512 pixels, recommended 800 x 800 pixels'/>

            <Image
                loading={imagesLoading}
                name='dark_logo'
                label='Dark Mode Logo'
                handleChange={handleManualUpdate}
                previewUrl={previewImages.currentDarkLogo}
                directUploadUrl={directUploadUrl}
                tooltip='Minimum 512 x 512 pixels, recommended 800 x 800 pixels. For use when using dark mode'/>

            <Image
                loading={imagesLoading}
                name='app_logo'
                handleChange={handleManualUpdate}
                previewUrl={previewImages.currentAppLogo}
                directUploadUrl={directUploadUrl} />

            <Image
                loading={imagesLoading}
                name='target_image'
                handleChange={handleManualUpdate}
                previewUrl={previewImages.currentTargetImage}
                directUploadUrl={directUploadUrl} />

            <a href="/edit_target.png" download="target.png">Download and modify this image, then upload</a>
        </div>
    )
}
