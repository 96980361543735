import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    moveCheckItemsBetweenTabs,
    selectCurrentCheck,
    selectModals,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import TabStatus from '@/features/AdvancedPointOfSale/components/tabs/TabStatus'
import { sortedAndFilteredTabs } from '@/features/AdvancedPointOfSale/lib/Checks'

export default function MoveCheckItemModal({ onClose=() => {}, onSuccess=() => {} }) {

    const dispatch                  = useDispatch()
    const currentCheck              = useSelector(selectCurrentCheck)
    const { manageCheckItem:modal } = useSelector(selectModals)
    const sortedTabs                = useMemo(() => sortedAndFilteredTabs(currentCheck.tabs.filter((t) => !t.is_closed)), [currentCheck])

    const [selectedTab, setSelectedTab] = useState(null)

    const handleSave = () => {
        dispatch(moveCheckItemsBetweenTabs(selectedTab.id)).then(() => onSuccess())
    }

    return !!modal?.tab?.id && <>
        {
            sortedTabs
                .filter((tab) => tab.id !== modal.tab.id && !tab?.payment_locked_by_id)
                .map((tab) => (
                    <div key={tab.id} className='menu-button'>
                        <button
                            key={tab.id}
                            className={tab.id === selectedTab?.id ? 'selected' : ''}
                            onClick={() => setSelectedTab(tab)}
                        >
                            <div className='d-flex align-content-center'>
                                { tab.is_tab_of_primary_check_owner && <i className='fas fa-star text-yellow mr-2' /> }

                                { tab.is_general && currentCheck.booking?.name || tab.name }

                                <TabStatus
                                    className={tab.id === selectedTab?.id ? 'text-white' : 'text-green'}
                                    tab={tab}
                                />
                            </div>

                            {
                                tab.id === selectedTab?.id && (
                                    <i className="fa-solid fa-check" />
                                )
                            }
                        </button>
                    </div>
                ))
        }

        <div className="modal-footer mx-n4 mt-4 px-4 pb-0 border-top border-thick border-top-gray0">
            <button
                children='Close'
                className='btn btn-outline-secondary text-white mr-auto'
                onClick={onClose}
            />

            <button
                children='Move'
                className='btn btn-primary ml-auto'
                disabled={!selectedTab}
                onClick={handleSave}
            />
        </div>
    </>
}
